<template>
  <div
    class='overflow-auto'
    style='height: calc(100vh - 300px);'>
    <table class='text-sm table-fixed'
      id='excelStocks'>
      <thead>
        <tr>
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.report_name'
            keyName='report_name'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='보고서명'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.share_code'
            keyName='share_code'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='종목코드'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.name'
            keyName='name'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='종목'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.market'
            keyName='market'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='시장'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.action'
            keyName='action'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='발행종류'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.corporate_method'
            keyName='corporate_method'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='증자방식'
            :optionObject='corporateAnnouncements'
            class='text-left' />

          <th class='text-right border-l'>신주의 수</th>
          <th class='text-right'>자금조달총액</th>
          <th class='text-left'>납입일</th>
          <th class='text-left'>배정기준일</th>
          <th class='text-left'>상장예정일</th>
          <th class='text-left'>할인율또는할증율</th>

          <th class='border-l'>배당기산일</th>
          <th class='text-left'>청약예정일(우리 사주조합 시작일)</th>
          <th class='text-left'>청약예정일(우리 사주조합 종료일)</th>
          <th class='text-left'>청약예정일(구주주 시작일)</th>
          <th class='text-left'>청약예정일(구주주 종료일)</th>

          <th class='text-right border-l'>신주의 종류와 수 (보통주)</th>
          <th class='text-right'>신주의 종류와 수 (우선주/기타주)</th>
          <th class='text-right border-l'>자금조달의 목적(시설자금)</th>
          <th class='text-right'>자금조달의 목적(영업양수자금)</th>
          <th class='text-right'>자금조달의 목적(운영자금)</th>
          <th class='text-right'>자금조달의 목적(채무상환자금)</th>
          <th class='text-right'>자금조달의 목적(타법인 증권 취득자금)</th>
          <th class='text-right'>자금조달의 목적(기타자금)</th>

          <th class='border-l'>Receipt Date</th>
          <th>Receipt Number</th>
        </tr>
      </thead>
      <tbody>
        <corporate-announcement-stock-row
          v-for='corporateAnnouncement in filteredData'
          :key='`stock-${corporateAnnouncement.id}-${corporateAnnouncement.name}`'
          :announcement='corporateAnnouncement' />
      </tbody>
    </table>
  </div>
</template>

<script>
import CorporateAnnouncementStockRow from '@/views/corporate_announcements/CorporateAnnouncementStockRow.vue'
import TableHeaderFilterSelect from '@/components/TableHeaderFilterSelect.vue'
import EventBus from '@/utils/event-bus'
import * as XLSX from 'xlsx-js-style'

export default {
  name: 'CorporateAnnouncementStocks',
  components: {
    CorporateAnnouncementStockRow,
    TableHeaderFilterSelect,
  },
  props: [
    'corporateAnnouncements',
    'dateRange',
  ],
  data () {
    return {
      sortDir: '',
      sortKey: '',
      filterTexts: {
        report_name: [],
        share_code: [],
        name: [],
        market: [],
        action: [],
        corporate_method: [],
      },
      filterKeys: [
        'report_name',
        'share_code',
        'name',
        'market',
        'action',
        'corporate_method',
      ],
    }
  },
  computed: {
    sortedData () {
      if (this.sortDir === '') {
        return this.corporateAnnouncements
      } else {
        this.sortData ( this.corporateAnnouncements, {key: this.sortKey, direction: this.sortDir} )
        return this.corporateAnnouncements
      }
    },
    filteredData () {
      return this.sortedData.filter( data => this.inAnyData(data, this.filterKeys, this.filterTexts) )
    },
  },
  methods: {
    inAnyData (data, filterKeys, filters) {
      if (filterKeys) {
        for (var ii = 0; ii < filterKeys.length; ii++) {
          var key = filterKeys[ii]
          if (!this.inAny (data[key], filters[key]))
            return false
        }
      }
      return true
    },
    inAny (source, filterTexts) {
      if (!filterTexts || filterTexts.length === 0)
        return true

      for (var ii = 0; ii < filterTexts.length; ii++) {
        if (source == filterTexts[ii])
          return true
      }
      return false
    },
  },
  mounted () {
    EventBus.$on('downloadExcel', () => {
      var excelData = XLSX.utils.table_to_sheet(document.getElementById('excelStocks'))
      var workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, excelData, '유상증자')
      XLSX.writeFile(workBook, `유상증자_${this.dateRange.start_date}_${this.dateRange.end_date}.xlsx`)
    })
  },
  beforeDestroy () {
    EventBus.$off('downloadExcel')
  },
}
</script>

<style lang='scss' scoped>
  th {
    @apply text-center py-3 pl-2 pr-6 border-b uppercase tracking-wide;
    position: sticky;
    top: 0;
    white-space: nowrap;
    background-color: #FFF;
    z-index: 2;
  }
  th:first-child {
    position: sticky;
    background-color: #FFF;
    left: 0;
    top: 0;
    z-index: 3;
  }
</style>
