<template>
  <div class='pb-8 px-16'>
    <div class='flex flex-row my-6'>
      <h1 class='mr-2 text-3xl font-semibold tracking-wide'>Announcements</h1>
      <button
        class='hidden border p-3 rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        @click='copyCSVToClipboard'>
        <clipboard-copy-icon class='inline-block' style='margin-top: -3px;' />
      </button>
    </div>
    <div class='flex flex-row my-6'>
      <el-date-picker
        v-model='date'
        style='width: 20rem;'
        type='daterange'
        placeholder='공시일자'
        :default-value='date'
        format='yyyy-MM-dd'
        @change='refreshAnnouncements'
        range-separator='~'
        start-placeholder='Start Date'
        end-placeholder='End Date'
        align='right'
        unlink-panels
        :picker-options='pickerOptions'>
      </el-date-picker>
      <select class='form-select w-64' v-model='selectedAction'>
        <option value=매자닌>매자닌</option>
        <optgroup v-for='(group, name) in groupActions' :label='name' :key='name'>
          <option v-for='(action, index) in group'
                   v-bind:key='`${name}-${index}`'
                   v-bind:value='action.action'>
            {{ action.action }}
          </option>
        </optgroup>
      </select>
      <el-radio-group v-model='exportStatus' size='large' class='px-4'>
        <el-radio-button label='전체'></el-radio-button>
        <el-radio-button label='Export'></el-radio-button>
      </el-radio-group>
      <button v-if='selectedAction === "매자닌"' class='hover:bg-gray-100 rounded-md ml-4 py-2 px-8 border' @click='saveUserDetail'>저장</button>
      <button class='hover:bg-gray-100 rounded-md ml-4 py-2 px-8 border' @click='exportExcel'>엑셀전환</button>
    </div>
    <corporate-announcement-stocks    v-if='isStocks' :dateRange='dateRange'    :corporate-announcements='filteredData' />
    <corporate-announcement-ipos      v-if='isIpos' :dateRange='dateRange'      :corporate-announcements='filteredData' />
    <corporate-announcement-bonds     v-if='isBonds' :dateRange='dateRange'     :corporate-announcements='filteredData' />
    <corporate-announcement-mezzanine v-if='isMezzanine' :dateRange='dateRange'
      :corporate-announcements='filteredData'
      :is-export='isExport'/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { ClipboardCopyIcon }         from '@vue-hero-icons/outline'
import CorporateAnnouncementStocks    from '@/views/corporate_announcements/CorporateAnnouncementStocks.vue'
import CorporateAnnouncementIpos      from '@/views/corporate_announcements/CorporateAnnouncementIpos.vue'
import CorporateAnnouncementBonds     from '@/views/corporate_announcements/CorporateAnnouncementBonds.vue'
import CorporateAnnouncementMezzanine from '@/views/corporate_announcements/CorporateAnnouncementMezzanine.vue'
import dayjs from 'dayjs'
import EventBus from '@/utils/event-bus'

export default {
  name: 'CorporateAnnouncements',
  components: {
    ClipboardCopyIcon,
    CorporateAnnouncementStocks,
    CorporateAnnouncementIpos,
    CorporateAnnouncementBonds,
    CorporateAnnouncementMezzanine,
  },
  data () {
    return {
      date: [dayjs().subtract(90, 'days'), dayjs()],
      updatableAnnouncements: [],
      exportStatus: '전체',
      userDetail: '원본',
      groupActions: {
        '주식': [
          { asset: 'stocks', action: '유상증자' },
          { asset: 'stocks', action: '증권신고서' },
        ],
        '채권': [
          { asset: 'bonds', action: '사채(전체)' },
          { asset: 'bonds', action: '전환사채' },
          { asset: 'bonds', action: '교환사채' },
          { asset: 'bonds', action: '신주인수권부사채' },
        ],
      },
      selectedAction: '매자닌',
      pickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  computed: {
    ...mapState('corporateAnnouncements', [
      'corporateAnnouncements',
    ]),
    ...mapGetters('corporateAnnouncements', [
      'getCSVString'
    ]),
    filteredData () {
      if (this.selectedAction === '사채(전체)' || this.selectedAction === '채권') {
        return this.corporateAnnouncements.filter(item => item.action.includes('사채'))
      }
      else if (this.selectedAction === '주식') {
        return this.corporateAnnouncements.filter(item => item.action === '유상증자')
      }
      else if (this.selectedAction === '매자닌') {
        return this.corporateAnnouncements.filter(item =>
          (item.action.includes('사채') || item.action === '유상증자')
          && this.exportStatus === '전체' ? true : item.user_check)
      }
      else {
        return this.corporateAnnouncements.filter(item => item.action === this.selectedAction)
      }
    },
    isStocks () {
      return this.selectedAction === '유상증자' || this.selectedAction === '주식'
    },
    isIpos () {
      return this.selectedAction === '증권신고서'
    },
    isBonds () {
      return this.selectedAction.includes('사채') || this.selectedAction === '채권'
    },
    isMezzanine () {
      return this.selectedAction === '매자닌'
    },
    isExport () {
      return this.exportStatus === 'Export'
    },
    dateRange () {
      return {
        start_date: dayjs(this.date[0]).format('YYYYMMDD'),
        end_date: dayjs(this.date[1]).format('YYYYMMDD')
      }
    },
  },
  methods: {
    ...mapActions('corporateAnnouncements', [
      'getCorporateAnnouncements',
      'putCorporateAnnouncements',
    ]),
    copyCSVToClipboard () {
      console.log('copy ')
    },
    refreshAnnouncements () {
      this.getCorporateAnnouncements(this.dateRange)
    },
    saveUserDetail () {
      this.putCorporateAnnouncements ().then( () => this.refreshAnnouncements() )
    },
    exportExcel () {
      EventBus.$emit('downloadExcel')
    }
  },
  mounted () {
    this.refreshAnnouncements()
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply text-left uppercase tracking-wide sticky top-0;
    white-space: nowrap;
  }
  th, td {
    @apply py-3 pl-2 pr-6 border-b;
  }

  td {
    @apply cursor-pointer text-gray-700;
  }

</style>
