<template>
  <tr class='bg-white hover:bg-blue-100'>
    <td class='text-center border-r'>
      <input
        type='checkbox'
        class='form-checkbox'
        value='announcement.user_check'
        :checked='announcement.user_check'
        name='exportCheck'
        @change='updateCheck'>
    </td>
    <td class='text-left'>
      <div class='inline-block w-32 whitespace-normal'>
        <a class='hover:underline' :href='announcementLink' target='_blank'>
          {{announcement.name}}
        </a>
      </div>
    </td>
    <td class='text-left'>{{announcement.share_code}}</td>
    <td class='text-left'>
      <div class='inline-block'>
        <a class='hover:underline' :href='announcementLink' target='_blank'>
          {{announcement.report_name}}
        </a>
      </div>
    </td>
    <td class='text-left'>{{market(announcement.market)}}</td>
    <td class='text-left'>{{announcement.action}}</td>
    <td class='text-left'>{{announcementMethod}}</td>
    <td class='text-right border-l'>{{issuingTimes}}</td>
    <td class='text-right'>{{numberStyle(quantityUk)}}</td>

    <td class='text-right'>
      <input type='text'
        v-bind:value='valueDetail("REFIXING")'
        v-on:input='updateDetail("REFIXING")'
        :disabled='!announcement.user_check'
        class='text-right w-16' />
    </td>

    <td class='text-right'>{{couponRate}}</td>
    <td class='text-right'>{{ytm}}</td>

    <td class='text-right'>
      <input type='text'
        v-bind:value='valueDetail("YTP")'
        v-on:input='updateDetail("YTP")'
        :disabled='!announcement.user_check'
        class='text-right w-12' />
        %
    </td>

    <td class='text-right'>
      <input type='text'
        v-bind:value='valueDetail("YTC")'
        v-on:input='updateDetail("YTC")'
        :disabled='!announcement.user_check'
        class='text-right w-12' />
        %
    </td>

    <td class='text-right'>
      <input type='text'
        v-bind:value='valueDetail("PUT")'
        v-on:input='updateDetail("PUT")'
        :disabled='!announcement.user_check'
        class='text-right w-12' />
        %
    </td>

    <td class='text-right'>
      <input type='text'
        v-bind:value='valueDetail("CALL")'
        v-on:input='updateDetail("CALL")'
        :disabled='!announcement.user_check'
        class='text-right w-12' />
        %
    </td>

    <td class='text-left border-l'>{{dateStyle(announcement.details['납입일'])}}</td>
    <td class='text-left'>{{dateStyle(announcement.details['사채만기일'])}}</td>

    <td class='text-right'>
      <input type='text'
        v-bind:value='valueDetail("발행내역")'
        v-on:input='updateDetail("발행내역")'
        :disabled='!announcement.user_check'
        class='text-left' />
    </td>

  </tr>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions } from 'vuex'


export default {
  name: 'CorporateAnnouncementMezzanineRow',
  props: [
    'announcement',
    'index',
    'exportStatus',
  ],
  computed: {
    announcementLink () {
      return this.announcement.url ? this.announcement.url : ''
    },
    quantityUk () {
      let amount =
        this.announcement.action.includes('사채') ? (Number(this.announcement.details['액면']) / 100000000).toFixed() :
          ((Number(this.announcement.details['시설자금']) + Number(this.announcement.details['영업양수자금']) + Number(this.announcement.details['운영자금']) +
            Number(this.announcement.details['채무상환자금']) + Number(this.announcement.details['타법인증권취득자금']) + Number(this.announcement.details['기타자금'])) / 100000000).toFixed()

      return parseInt(amount)
    },
    isBond () {
      return this.announcement.action.includes('사채')
    },
    announcementMethod () {
      return this.isBond ? this.announcement.details['사채발행방법'] : this.announcement.details['증자방식']
    },
    issuingTimes () {
      return this.isBond ? this.announcement.details['회차'] : ''
    },
    issueDetails () {
      return this.isBond ? this.announcement.details['발행내역'].join(', ') : ''
    },
    couponRate () {
      return this.isBond && this.announcement.details['표면이자율'] ? Number(this.announcement.details['표면이자율']).toFixed(1) + ' %' : ''
    },
    ytm () {
      return this.isBond && this.announcement.details['만기이자율'] ? Number(this.announcement.details['만기이자율']).toFixed(1) + ' %'  : ''
    },
  },
  methods: {
    ...mapActions('corporateAnnouncements', [
      'updateUserCheck',
      'updateUserDetail',
    ]),
    market (rawMarket) {
      let str = ''
      switch (rawMarket) {
        case 'kospi':
          str = '유'
          break
        case 'kosdaq':
          str = '코'
          break
        case 'konex':
          str = '넥'
          break
        case 'etc':
          str = '기'
          break
      }
      return str
    },
    numberStyle (number) {
      if (number) {
        return Number(number).toLocaleString()
      } else {
        return ''
      }
    },
    dateStyle (string) {
      if (string) {
        return (string.length === 8) ? dayjs(string).format('YYYY-MM-DD') : string
      } else {
        return ''
      }
    },
    valueDetail (key) {
      if (this.announcement.user_details[key]) {
        return this.announcement.user_details[key]
      } else if (this.announcement.details[key]) {
        return this.announcement.details[key].join(', ')
      } else {
        return ''
      }
    },
    updateDetail (key) {
      this.updateUserDetail ( {id: this.announcement.id, key: key, value: event.target.value} )
    },
    updateCheck () {
      this.updateUserCheck({ id: this.announcement.id, check: this.announcement.user_check ? false : true} )
    },
  },
}
</script>

<style lang='scss' scoped>
  td {
    @apply py-3 pl-2 pr-6 border-b;
    white-space: nowrap;
  }
  td:nth-child(1) {
    position: sticky;
    background-color: #FFF;
    left: 0;
  }
  td:nth-child(2) {
    position: sticky;
    background-color: #FFF;
    left: 45px;
  }
</style>
