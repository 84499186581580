<template>
  <div
    class='overflow-auto'
    style='height: calc(100vh - 300px);'>
    <table class='text-sm table-fixed'
      id='excelBonds'>
      <thead>
        <tr>
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.report_name'
            keyName='report_name'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='보고서명'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.share_code'
            keyName='share_code'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='종목코드'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.name'
            keyName='name'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='종목'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.market'
            keyName='market'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='시장'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.action'
            keyName='action'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='발행종류'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <th class='text-right border-l'>회차</th>
          <th class='text-right'>금액(억)</th>
          <th class='text-right'>표면</th>
          <th class='text-right'>YTM</th>
          <th class='text-right'>사채만기</th>
          <th class='text-right'>행사가 </th>
          <th class='text-right'>현재가 </th>
          <th class='text-right'>상승률</th>
          <th class='text-right'>전환청구시작일</th>
          <th class='text-right'>전환청구종료일</th>
          <th class='text-right'>워런트잔여일</th>
          <th class='text-right'>리픽싱</th>
          <th class='text-right'>PUT</th>
          <th class='text-right'>납입일</th>
          <th class='text-right'>BuyBack</th>
          <th class='text-right'>인수자</th>

          <th class='border-l'>Receipt Date</th>
          <th>Receipt Number</th>
        </tr>
      </thead>
      <tbody>
        <corporate-announcement-bond-row
          v-for='corporateAnnouncement in filteredData'
          :key='`corporateAnnouncement-${corporateAnnouncement.id}`'
          :announcement='corporateAnnouncement' />
      </tbody>
    </table>
  </div>
</template>

<script>
import CorporateAnnouncementBondRow from '@/views/corporate_announcements/CorporateAnnouncementBondRow.vue'
import TableHeaderFilterSelect from '@/components/TableHeaderFilterSelect.vue'
import EventBus from '@/utils/event-bus'
import * as XLSX from 'xlsx-js-style'

export default {
  name: 'CorporateAnnouncementBonds',
  components: {
    CorporateAnnouncementBondRow,
    TableHeaderFilterSelect,
  },
  props: [
    'corporateAnnouncements',
    'dateRange',
  ],
  data () {
    return {
      sortDir: '',
      sortKey: '',
      filterTexts: {
        report_name: [],
        share_code: [],
        name: [],
        market: [],
        action: [],
        corporate_method: [],
      },
      filterKeys: [
        'report_name',
        'share_code',
        'name',
        'market',
        'action',
        'corporate_method',
      ],
    }
  },
  computed: {
    sortedData () {
      if (this.sortDir === '') {
        return this.corporateAnnouncements
      } else {
        this.sortData ( this.corporateAnnouncements, {key: this.sortKey, direction: this.sortDir} )
        return this.corporateAnnouncements
      }
    },
    filteredData () {
      return this.sortedData.filter( data => this.inAnyData(data, this.filterKeys, this.filterTexts) )
    },
  },
  methods: {
    inAnyData (data, filterKeys, filters) {
      if (filterKeys) {
        for (var ii = 0; ii < filterKeys.length; ii++) {
          var key = filterKeys[ii]
          if (!this.inAny (data[key], filters[key]))
            return false
        }
      }
      return true
    },
    inAny (source, filterTexts) {
      if (!filterTexts || filterTexts.length === 0)
        return true

      for (var ii = 0; ii < filterTexts.length; ii++) {
        if (source == filterTexts[ii])
          return true
      }
      return false
    },
  },
  mounted () {
    EventBus.$on('downloadExcel', () => {
      var excelData = XLSX.utils.table_to_sheet(document.getElementById('excelBonds'))
      var workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, excelData, '사채')
      XLSX.writeFile(workBook, `사채_${this.dateRange.start_date}_${this.dateRange.end_date}.xlsx`)
    })
  },
  beforeDestroy () {
    EventBus.$off('downloadExcel')
  },
}
</script>

<style lang='scss' scoped>
  th {
    @apply text-center py-3 pl-2 pr-6 border-b uppercase tracking-wide;
    position: sticky;
    top: 0;
    white-space: nowrap;
    background-color: #FFF;
    z-index: 2;
  }
  th:first-child {
    position: sticky;
    background-color: #FFF;
    left: 0;
    top: 0;
    z-index: 3;
  }

</style>
