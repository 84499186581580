<template>
  <tr class='hover:bg-blue-100'>
    <td class=''>
      <div class='inline-block'>
        <a class='hover:underline' :href='announcementLink' target='_blank'>
          {{announcement.report_name}}
        </a>
      </div>
    </td>
    <td class=''>{{announcement.share_code}}</td>
    <td class=''>{{announcement.name}}</td>
    <td class=''>{{market(announcement.market)}}</td>

    <td class='text-right border-l'>{{ announcement.details['진행상황'] }}</td>
    <td>{{ announcement.details['시장구분'] }}</td>
    <td>{{ listedPublicOffering }}</td>
    <td class='text-right'>{{ numberStyle(announcement.details['총공모주식수']) }}</td>
    <td class='text-right'>{{ numberStyle(announcement.details['액면가']) }}</td>
    <td>{{ announcement.details['희망공모가액'] }}</td>
    <td class='text-right'>{{ numberStyle(announcement.details['확정공모가']) }}</td>
    <td>{{ announcement.details['주간사'] }}</td>
    <td>{{ announcement.details['수요예측시작일'] }}</td>
    <td>{{ announcement.details['수요예측종료일'] }}</td>
    <td>{{ announcement.details['공모청약시작일'] }}</td>
    <td>{{ announcement.details['공모청약종료일'] }}</td>
    <td>{{ announcement.details['납입일'] }}</td>
    <td>{{ announcement.details['환불일'] }}</td>
    <td>{{ announcement.details['상장일'] }}</td>

    <td class='text-right border-l'>{{announcement.receipt_date}}</td>
    <td class=''>{{announcement.receipt_number}}</td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CorporateAnnouncementIpoRow',
  props: ['announcement'],
  computed: {
    announcementLink () {
      return this.announcement.url ? this.announcement.url : ''
    },
    newShares () {
      return !this.announcement.details['신주모집'] ? '' : '신주: ' + this.announcement.details['신주모집'] + ' % '
    },
    oldShares () {
      return !this.announcement.details['구주매출'] ? '' : '구주: ' + this.announcement.details['구주매출'] + ' %'
    },
    listedPublicOffering () {
      return this.newShares + this.oldShares
    },
  },
  methods: {
    ...mapActions('investmentEvents', [
      'createInvestmentEventByAnnouncement',
    ]),
    ...mapActions('corporateAnnouncements', [
      'corporateAnnouncementIpoDetailsOpen',
    ]),
    addToCalendar (announcementId) {
      this.createInvestmentEventByAnnouncement(announcementId)
    },
    market (rawMarket) {
      let str = ''
      switch (rawMarket) {
        case 'kospi':
          str = '유'
          break
        case 'kosdaq':
          str = '코'
          break
        case 'konex':
          str = '넥'
          break
        case 'etc':
          str = '기'
          break
      }
      return str
    },
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
  },
}
</script>

<style lang='scss' scoped>
  td {
    @apply py-3 pl-2 pr-6 border-b whitespace-no-wrap;
  }

</style>
