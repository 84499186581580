<template>
  <tr class='bg-white hover:bg-blue-100'>
    <td class='text-center w-8'>
      <input
        type='checkbox'
        class='form-checkbox'
        value='announcement.user_check'
        :checked='announcement.user_check'
        name='exportCheck'
        @change='updateCheck'>
    </td>
    <td class='whitespace-normal'>
      <input type='text'
        v-bind:value='valueDetail("납입일")'
        v-on:input='updateDetail("납입일")'
        class='form-input text-left w-32' />
    </td>
    <td class='text-left'>
      <div class='inline-block w-32 pl-2'>
        <a class='hover:underline w-full' :href='announcementLink' target='_blank'>
          {{announcement.name}}
        </a>
      </div>
    </td>
    <td>
      <v-select
        class='text-left w-32'
        v-model='userMarket'
        @close='updateMarket'
        :options='marketList'
        :close-on-select='true' >
      </v-select>
    </td>
    <td>
      <input type='text'
        v-bind:value='actionName()'
        v-on:input='updateDetail("발행종류")'
        class='form-input text-left w-24' />
    </td>
    <td>
      <v-select
        class='text-left w-32'
        v-model='userVenture'
        @close='updateVenture'
        :options='ventureList'
        :close-on-select='true' >
      </v-select>
    </td>
    <td>
      <input type='text'
        v-bind:value='valueDetail("시가총액")'
        v-on:input='updateDetail("시가총액")'
        class='form-input text-right w-20' />
    </td>
    <td>
      <input type='text'
        v-bind:value='amount()'
        v-on:input='updateDetail("금액")'
        class='form-input text-right w-16' />
    </td>
    <td>
      <input type='text'
        v-bind:value='valueDetail("만기")'
        v-on:input='updateDetail("만기")'
        class='form-input text-right w-10' />
        년
    </td>
    <td>
      <input type='text'
        v-bind:value='valueDetail("PUT")'
        v-on:input='updateDetail("PUT")'
        class='form-input text-right w-10' />
        년 
    </td>
    <td>
      <input type='text'
        v-bind:value='couponRate()'
        v-on:input='updateDetail("표면이자율")'
        class='form-input text-right w-16' />
        %
    </td>
    <td>
      <input type='text'
        v-bind:value='valueDetail("만기이자율")'
        v-on:input='updateDetail("만기이자율")'
        class='form-input text-right w-16' />
        %
    </td>
    <td>
      <input type='text'
        v-bind:value='valueDetail("CALL")'
        v-on:input='updateDetail("CALL")'
        class='form-input text-right w-16' />
        %
    </td>
    <td>
      <input type='text'
        v-bind:value='valueDetail("REFIXING")'
        v-on:input='updateDetail("REFIXING")'
        class='form-input text-right w-16' />
        %
    </td>
    <td>
      <input type='text'
        v-bind:value='valueDetail("검토")'
        v-on:input='updateDetail("검토")'
        class='form-input text-center w-10' />
    </td>
    <td>
      <input type='text'
        v-bind:value='valueDetail("주관")'
        v-on:input='updateDetail("주관")'
        class='form-input w-32' />
    </td>
    <td>
      <input type='text'
        v-bind:value='valueDetail("섹터")'
        v-on:input='updateDetail("섹터")'
        class='form-input w-40' />
    </td>
    <td class='w-full' style='min-width: 30rem;'>
      <textarea type='text'
        v-bind:value='valueDetail("발행내역")'
        v-on:input='updateDetail("발행내역")'
        class='rounded-md w-full pl-4 py-1 pr-6 h-16 leading-tight text-base'>
      </textarea>
    </td>
  </tr>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions } from 'vuex'


export default {
  name: 'CorporateAnnouncementMezzanineExport',
  props: [
    'announcement',
    'index',
    'exportStatus',
  ],
  data () {
    return {
      marketList: ['코스피', '코스닥','코넥스'],
      userMarket: this.marketName(),
      ventureList: ['', '벤처','7년'],
      userVenture: this.ventureName(),
    }
  },
  computed: {
    announcementLink () {
      return this.announcement.url ? this.announcement.url : ''
    },
  },
  methods: {
    ...mapActions('corporateAnnouncements', [
      'updateUserCheck',
      'updateUserDetail',
    ]),
    marketName () {
      let str = ''

      if (this.announcement.user_details['시장'])  {
        str = this.announcement.user_details['시장']
      } else {
        switch (this.announcement.market) {
          case 'kospi':
            str = '코스피'
            break
          case 'kosdaq':
            str = '코스닥'
            break
          case 'konex':
            str = '코넥스'
            break
          case 'etc':
            str = '기타'
            break
        }
      }

      return str
    },
    couponRate () {
      let retVal = ''

      if (this.announcement.user_details['표면이자율']) {
        retVal = Number(this.announcement.user_details['표면이자율'])
      } else if (this.announcement.details['표면이자율']) {
        retVal =  Number(this.announcement.details['표면이자율'])
      }

      return retVal
    },
    ventureName () {
      let str = ''
      if (this.announcement.user_details['벤처여부']) {
        str = this.announcement.user_details['벤처여부']
      } else {
        str = ''
      }

      return str
    },
    numberStyle (number) {
      if (number) {
        return Number(number).toLocaleString()
      } else {
        return 0
      }
    },
    dateStyle (string) {
      if (string) {
        return (string.length === 8) ? dayjs(string).format('YYYY-MM-DD') : string
      } else {
        return ''
      }
    },
    valueDetail (key) {
      let retVal = ''

      if (this.announcement.user_details[key]) {
        if (key === '납입일')
          retVal =  this.dateStyle(this.announcement.user_details[key])
        else
          retVal = this.announcement.user_details[key]
      } else if (this.announcement.details[key]) {
        if (key === '발행내역')
          retVal = this.announcement.details[key].join(', ')
        else if (key === '납입일')
          retVal = this.dateStyle(this.announcement.details[key])
        else
          retVal = this.announcement.details[key]
      } else {
        retVal = ''
      }

      return retVal
    },
    actionName () {
      if (this.announcement.user_details['발행종류'])
        return this.announcement.user_details['발행종류']
      else
        return this.announcement.action
    },
    detailAmount () {
      let amount = 0
        if (this.announcement.action.includes('사채')) {
          amount += this.announcement.details['액면'] ? Number(this.announcement.details['액면']) : 0
        } else {
          amount += this.announcement.details['시설자금'] ? Number(this.announcement.details['시설자금']) : 0
          amount += this.announcement.details['영업양수자금'] ? Number(this.announcement.details['영업양수자금']) : 0
          amount += this.announcement.details['운영자금'] ? Number(this.announcement.details['운영자금']) : 0
          amount += this.announcement.details['채무상환자금'] ? Number(this.announcement.details['채무상환자금']) : 0
          amount += this.announcement.details['타법인증권취득자금'] ? Number(this.announcement.details['타법인증권취득자금']) : 0
          amount += this.announcement.details['기타자금'] ? Number(this.announcement.details['기타자금']) : 0
        }

      return (amount / 100000000).toFixed()
    },
    amount () {
      if (this.announcement.user_details['금액'])
        return this.announcement.user_details['금액']
      else
        return this.detailAmount()
    },
    updateDetail (key) {
      this.updateUserDetail ( {id: this.announcement.id, key: key, value: event.target.value} )
    },
    updateCheck () {
      this.updateUserCheck({ id: this.announcement.id, check: this.announcement.user_check ? false : true} )
    },
    updateMarket () {
      this.updateUserDetail ( {id: this.announcement.id, key: '시장', value: this.userMarket} )
    },
    updateVenture () {
      this.updateUserDetail ( {id: this.announcement.id, key: '벤처여부', value: this.userVenture} )
    },
  },
}
</script>

<style lang='scss' scoped>
  td {
    @apply py-2 pl-2 pr-2 border-b border-l border-r;
    white-space: nowrap;
    z-index: 4;
  }
  td:nth-child(1) {
    position: sticky;
    background-color: #FFF;
    left: 0;
  }
  td:nth-child(2) {
    position: sticky;
    background-color: #FFF;
    left: 43px;
  }
  td:nth-child(3) {
    position: sticky;
    background-color: #FFF;
    left: 163px;
  }
</style>
