<template>
  <tr class='hover:bg-blue-100'>
    <td class=''>
      <div class='inline-block'>
        <a class='hover:underline' :href='announcementLink' target='_blank'>
          {{announcement.report_name}}
        </a>
      </div>
    </td>
    <td class='text-left'>{{announcement.share_code}}</td>
    <td class='text-left'>{{announcement.name}}</td>
    <td class='text-left'>{{market(announcement.market)}}</td>
    <td class='text-left'>{{announcement.action}}</td>

    <td class='text-right border-l'>{{ numberStyle(announcement.details['회차'] || 0) }}</td>
    <td class='text-right'>{{ numberStyle(quantityUk) }}</td>
    <td class='text-right'>{{announcement.details['표면이자율']}}</td>
    <td class='text-right'>{{announcement.details['만기이자율']}}</td>
    <td class='text-left'>{{dateStyle(announcement.details['사채만기일'])}}</td>
    <td class='text-right'>{{ numberStyle(announcement.details['전환가액'] || 0) }}</td>
    <td class='text-left'></td>
    <td class='text-left'></td>

    <td class='text-left border-l'>{{dateStyle(announcement.details['전환청구시작일'])}}</td>
    <td class='text-left'>{{dateStyle(announcement.details['전환청구종료일'])}}</td>
    <td class='text-left'></td>

    <td class='text-left border-l'></td>
    <td class='text-left'></td>
    <td class='text-left'>{{dateStyle(announcement.details['납입일'])}}</td>
    <td class='text-left'></td>
    <td class='text-left'>{{ pubish() }}</td>

    <td class='text-left border-l'>{{announcement.receipt_date}}</td>
    <td class='text-left'>{{announcement.receipt_number}}</td>
  </tr>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'CorporateAnnouncementBondRow',
  props: ['announcement'],
  computed: {
    announcementLink () {
      return this.announcement.url ? this.announcement.url : ''
    },
    hasAnnouncementDetails () {
      return !this.announcement.details
    },
    quantityUk () {
      return this.hasAnnouncementDetails ? (Number(this.announcement.details['액면']) / 100000000).toFixed() : 0
    }
  },
  methods: {
    market (rawMarket) {
      let str = ''
      switch (rawMarket) {
        case 'kospi':
          str = '유'
          break
        case 'kosdaq':
          str = '코'
          break
        case 'konex':
          str = '넥'
          break
        case 'etc':
          str = '기'
          break
      }
      return str
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
    dateStyle (string) {
      if (string) {
        return (string.length === 8) ? dayjs(string).format('YYYY-MM-DD') : string
      } else {
        return ''
      }
    },
    pubish () {
      return this.announcement.details['발행내역'] ? this.announcement.details['발행내역'].join(', ') : ''
    },
  },
}
</script>

<style lang='scss' scoped>
  td {
    @apply py-3 pl-2 pr-6 border-b;
    white-space: nowrap;
  }
  td:first-child {
    position: sticky;
    background-color: #FFF;
    left: 0;
  }
</style>
