<template>
  <div
    class='overflow-auto'
    style='height: calc(100vh - 300px);'>
    <table class='text-sm table-fixed'
      id='excelMezzanine'>
      <thead>
        <tr v-if='isExport' class='bg-white'>
          <th class='border-r border-l text-center'>출력</th>
          <th class='border-r'>납입일</th>
          <th class='border-r'>종목</th>
          <th class='border-r'>시장</th>
          <th class='border-r'>발행종류</th>
          <th class='border-r'>벤처여부</th>
          <th class='border-r'>시가총액(억)</th>
          <th class='text-right border-r'>금액(억)</th>
          <th class='text-right border-r'>만기</th>
          <th class='text-right border-r'>Put</th>
          <th class='text-right border-r'>표면</th>
          <th class='text-right border-r'>YTM</th>
          <th class='text-right border-r'>Call</th>
          <th class='text-right border-r'>Refixing</th>
          <th class='border-r'>검토</th>
          <th class='border-r'>주관</th>
          <th class='border-r'>섹터</th>
          <th class='border-r'>인수인</th>
        </tr>
        <tr v-else class='bg-white'>
          <th class='border-r text-center'>출력</th>
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.name'
            keyName='name'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='종목'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.share_code'
            keyName='share_code'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='종목코드'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.report_name'
            keyName='report_name'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='보고서명'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.market'
            keyName='market'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='시장'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.action'
            keyName='action'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='발행종류'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.corporate_method'
            keyName='corporate_method'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='증자방식'
            :optionObject='corporateAnnouncements'
            class='text-left' />
          <th class='border-l'>회차</th>
          <th class='text-right'>금액(억)</th>
          <th class='text-right'>Refixing</th>
          <th class='text-right'>표면</th>
          <th class='text-right'>YTM</th>
          <th class='text-right'>YTP</th>
          <th class='text-right'>YTC</th>
          <th class='text-right'>PUT</th>
          <th class='text-right border-r'>CALL</th>
          <th>납입일</th>
          <th>만기일</th>
          <th>인수자</th>
        </tr>
      </thead>
      <tbody v-if='isExport'>
        <corporate-announcement-mezzanine-export
          v-for='(corporateAnnouncement, index) in corporateAnnouncements'
          :key='`corporateAnnouncement-${corporateAnnouncement.id}`'
          :announcement='corporateAnnouncement'
          :index='index' />
      </tbody>
      <tbody v-else>
        <corporate-announcement-mezzanine-row
          v-for='(corporateAnnouncement, index) in filteredData'
          :key='`corporateAnnouncement-${corporateAnnouncement.id}`'
          :announcement='corporateAnnouncement'
          :index='index' />
      </tbody>
    </table>
  </div>
</template>

<script>
import CorporateAnnouncementMezzanineRow    from '@/views/corporate_announcements/CorporateAnnouncementMezzanineRow.vue'
import CorporateAnnouncementMezzanineExport from '@/views/corporate_announcements/CorporateAnnouncementMezzanineExport.vue'
import TableHeaderFilterSelect from '@/components/TableHeaderFilterSelect.vue'
import EventBus from '@/utils/event-bus'
import * as XLSX from 'xlsx-js-style'

export default {
  name: 'CorporateAnnouncementMezzanine',
  components: {
    CorporateAnnouncementMezzanineRow,
    CorporateAnnouncementMezzanineExport,
    TableHeaderFilterSelect,
  },
  props: [
   'corporateAnnouncements',
   'dateRange',
   'isExport',
  ],
  data () {
    return {
      sortDir: '',
      sortKey: '',
      filterTexts: {
        report_name: [],
        share_code: [],
        name: [],
        market: [],
        action: [],
        corporate_method: [],
      },
      filterKeys: [
        'report_name',
        'share_code',
        'name',
        'market',
        'action',
        'corporate_method',
      ],
    }
  },
  computed: {
    sortedData () {
      if (this.sortDir === '') {
        return this.corporateAnnouncements
      } else {
        this.sortData ( this.corporateAnnouncements, {key: this.sortKey, direction: this.sortDir} )
        return this.corporateAnnouncements
      }
    },
    filteredData () {
      return this.sortedData.filter( data => this.inAnyData(data, this.filterKeys, this.filterTexts) )
    },
  },
  methods: {
    mezzanineComponent () {
      return this.isExport ? 'corporate-announcement-mezzanine-export' : 'corporate-announcement-mezzanine-row'
    },
    inAnyData (data, filterKeys, filters) {
      if (filterKeys) {
        for (var ii = 0; ii < filterKeys.length; ii++) {
          var key = filterKeys[ii]
          if (!this.inAny (data[key], filters[key]))
            return false
        }
      }
      return true
    },
    inAny (source, filterTexts) {
      if (!filterTexts || filterTexts.length === 0)
        return true

      for (var ii = 0; ii < filterTexts.length; ii++) {
        if (source == filterTexts[ii])
          return true
      }
      return false
    },
  },
  mounted () {
    EventBus.$on('downloadExcel', () => {
      var excelData = XLSX.utils.table_to_sheet(document.getElementById('excelMezzanine'))
      var workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, excelData, '매자닌')
      XLSX.writeFile(workBook, `매자닌_${this.dateRange.start_date}_${this.dateRange.end_date}.xlsx`)
    })
  },
  beforeDestroy () {
    EventBus.$off('downloadExcel')
  },
}
</script>

<style lang='scss' scoped>
  th {
    @apply text-center py-3 pl-2 pr-2 border-b uppercase tracking-wide sticky top-0;
    background-color: #FFF;
    white-space: nowrap;
    z-index: 2;
  }
  th:nth-child(1),
  th:nth-child(2),
  th:nth-child(3) {
    position: sticky;
    background-color: #FFF;
    top: 0;
    z-index: 3;
  }
  th:nth-child(1) {
    left: 0;
  }
  th:nth-child(2) {
    left: 43px;
  }
  th:nth-child(3) {
    left: 163px;
  }
</style>
